require('./bootstrap');

jQuery(document).ready(function(){
    jQuery(document).on('click', '.form-group .show-password', function(e){
        e.preventDefault();

        var input_password = jQuery(this).parents().eq(0).find('.form-control');

        if(input_password.attr('type') == 'text'){
            input_password.attr('type', 'password');
            jQuery(this).find('.bi').addClass('bi-eye-fill');
            jQuery(this).find('.bi').removeClass('bi-eye-slash-fill');
        }
        else{
            if(input_password.attr('type') == 'password'){
                input_password.attr('type', 'text');
                jQuery(this).find('.bi').addClass('bi-eye-slash-fill');
                jQuery(this).find('.bi').removeClass('bi-eye-fill');
            }
        }
    });
    
    if(jQuery('.select2').length > 0){
        jQuery('.select2').each(function(index, select2){
            $(select2).select2({
                language: {
                    noResults: function (params) {
                        return "آیتمی یافت نشد";
                    }
                }
            });
        });
    }

    jQuery(document).on('click', '.image-box .actions button[name="delete"]', function(){
        jQuery(this).parents().eq(0).fadeOut();
        jQuery(this).parents().eq(1).find('.delete-actions').fadeIn();
    });

    jQuery(document).on('click', '.image-box .delete-actions button[name="cancel"]', function(){
        jQuery(this).parents().eq(0).fadeOut();
        jQuery(this).parents().eq(1).find('.actions').fadeIn();
    });

    jQuery(document).on('click', '.image-box .delete-actions button[name="confirm"]', function(){
        
        jQuery(this).parents().eq(0).fadeOut();
        jQuery(this).parents().eq(1).find('.actions').fadeIn();

        var image_preview = jQuery(this).parents().eq(1).find('img.preview');
        var input_type = jQuery(this).parents().eq(1).find('input[type="file"]');

        image_preview.fadeOut();
        image_preview.attr('src', '');
        jQuery(this).parents().eq(1).find('img:not(.preview)').fadeIn();
        jQuery(this).parents().eq(1).find('button[name="delete"]').fadeOut();

        input_type.attr('type', 'text');
        input_type.attr('val', '');
        input_type.attr('type', 'file');
    });
});

